'use client';

import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { AnimatedDiv } from '../animation/Animation';

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @keyframes ldio-4ro331xvst8 {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  .ldio-4ro331xvst8 div {
    position: absolute;
    width: 46.98px;
    height: 46.98px;
    border: 5.22px solid ${({ theme }) => theme.primary};
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-4ro331xvst8 div {
    animation: ldio-4ro331xvst8 0.5681818181818182s linear infinite;
    top: 50px;
    left: 50px;
  }
  .loadingio-spinner-rolling-t61fu8i5jyi {
    width: 100px;
    height: 100px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
  }
  .ldio-4ro331xvst8 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-4ro331xvst8 div {
    box-sizing: content-box;
  }
  /* generated by https://loading.io/ */
`;

export const FrameWrapper = ({ title, height = 1350, ...props }) => {
  const ref = useRef();
  // const [height, setHeight] = useState(100);
  const [loading, setLoading] = useState(true);

  const onLoad = () => {
    if (ref) {
      // const refHeight = ref.current.contentWindow.parent.innerHeight;
      // setHeight(refHeight + 50);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else {
      //   setHeight(0);
    }
  };

  return (
    <>
      {/* {loading && (
        <AnimatedDiv key="iframe_spinner">
          <Spinner>
            <div className="loadingio-spinner-rolling-t61fu8i5jyi">
              <div className="ldio-4ro331xvst8">
                <div></div>
              </div>
            </div>
          </Spinner>
        </AnimatedDiv>
      )} */}

      {/* <motion.div
        animate={{
          opacity: loading ? 0 : 1,
          y: loading ? 100 : 0,
          transition: {
            duration: 0.5,
            type: 'ease',
          },
        }}
      > */}
      <iframe
        title={title}
        {...props}
        ref={ref}
        onLoad={onLoad}
        scrolling="no"
        style={
          props.style
            ? props.style
            : {
                width: '100%',
                height: height,
                border: 'none',
              }
        }
      ></iframe>
      {/* </motion.div> */}
    </>
  );
};
